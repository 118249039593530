<template>
  <q-dialog v-model="show" persistent>
    <q-card class="form" >
      <q-card-section class="text-center text-white text-bold bg-light-blue-6">
        Daftar
      </q-card-section>
      <q-card-section class="">
        <form
          @submit.prevent.stop="createAccount"
          @reset.prevent.stop="onReset"
        >
          <div style="width: 100%;">
            
              <q-input outlined v-model="user.name" ref="name" label="Nama" @keyup.enter="$refs.pass.focus()" dense :rules="[(val) => !!val || 'Wajib diisi']"/>
              <q-input outlined v-model="user.username" ref="usrname" label="Alamat Email" @keyup.enter="$refs.pass.focus()" dense :rules="[(val) => !!val || 'Wajib diisi']"/>
              <q-input outlined v-model="user.no_hp" ref="no_hp" label="No HP" @keyup.enter="$refs.pass.focus()" dense :rules="[(val) => !!val || 'Wajib diisi']"/>
          
          </div>

          <div class="row q-py-md justify-between">
            <q-btn outline  style="width: 120px;" color="light-blue-6" label="Batal" @click="$router.go(-1)" no-caps/>
            <q-btn style="width: 120px;color:white;" color="light-blue-6" label="Buat Akun" type="submit" no-caps />
            <div class="row text-grey-8 q-pt-md justify-center items-center full-width">
              Sudah punya akun? <q-btn flat color="primary" class="q-px-xs" dense no-caps @click="$router.push('/login')" label="login" />
            </div>
          </div>
        </form>
      </q-card-section>
    </q-card>

  </q-dialog>
</template>

<script>
import md5 from 'md5'
export default {
  name: "Daftar",
  data: function() {
    return {
      user: {},
      model: null,
      isPwd: true,
      remember: false,
      show: true,
    };
  },
  methods: {
    createAccount(){

      this.$refs.name.validate();
      this.$refs.usrname.validate();
      this.$refs.no_hp.validate();
      if (
        this.$refs.name.hasError ||
        this.$refs.usrname.hasError ||
        this.$refs.no_hp.hasError
      ) {
        this.formHasError = true;
      } 
      else {
        let rand = Math.random().toString(36).substring(6).toLowerCase();
  
        this.user.password = md5(rand)

        this.$http.get('/check_account/'+ this.user.username ,{} )
        .then (result => {
          if(result.data.count > 0){
              this.$q.notify({
                icon: "warning",
                color: "negative",
                message: "Email sudah terdaftar",
              });
          }
          else{
            
            this.$http.post('/create_account', this.user, {})
            .then (result => {
      
              this.$http.post('/send_username/'+ this.user.username + '/' + rand,{} )
              .then (result => {
                console.log('sent');
                })
              this.$q.notify({
                icon: "done",
                color: "positive",
                message: "Pembuatan akun berhasil, Silahkan cek email anda",
              });
            })

          }
        })

      }

    }
  },
};
</script>
<style lang="scss" scoped>

.main-container{
  z-index: 2001; background-color: #E3F2F8;
}
.form{
  width: 300px; 
}
label{
  padding-top: 10px; padding-bottom: 15px;
  color: #505050; font-weight: bold;
}
.bg-blur {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.fg-blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px) brightness(.5);
  position: absolute;

}
</style>
